import './App.css';
import React, {Component, Suspense} from 'react'
import {HashRouter, Route, Routes} from 'react-router-dom'
import './scss/style.scss'
import Login from '../src/views/Login'
import axios from "axios";


const token_key = "cmr777-token";

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loggedIn: false
        }

    }

    loginSuccessCallback = (token) => {
        sessionStorage.setItem(token_key, JSON.stringify(token));
        this.setState({
            loggedIn: true
        })
//        axios.defaults.headers.common['Authorization'] = token;
//        alert(token);
    }


    logout = () => {
        sessionStorage.removeItem("cmr777-token");
        axios.defaults.headers.common['Authorization'] = '';
        this.setState({
            loggedIn: false
        })
    }


    render() {

        const loading = (
            <div className="pt-3 text-center">
                <div className="sk-spinner sk-spinner-pulse"></div>
            </div>
        )

        const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

        return (
            <HashRouter>
                <Suspense fallback={loading}>
                    <Routes>
                        <Route path="*" name="Home"
                               element={!this.state.loggedIn ?
                                   <Login loginSuccessCallback={this.loginSuccessCallback}/> :
                                   <DefaultLayout logoutCallBack={this.logout}/>}/>
                    </Routes>
                </Suspense>
            </HashRouter>
        );
    }

}

export default App;
