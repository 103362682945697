import React, {Component} from 'react'
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {cilLockLocked, cilUser} from '@coreui/icons'
import API from '../axios-config'

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: ''
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        await API.post(`login`, {"username": `${this.state.username}`, "password": `${this.state.password}`})
            .then(res => {
                this.props.loginSuccessCallback(res.data);
            }).catch(err => {
                console.log(err);
                alert("Invalid username or password")
            });
    }

    render() {
        return (

            <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
                <CContainer>
                    <CRow className="justify-content-center">
                        <CCol md={
                            8
                        }

                        >
                            <CCardGroup>
                                <CCard className="p-4">
                                    <CCardBody>
                                        <CForm>
                                            <h1>Login</h1>
                                            <p className="text-medium-emphasis">Sign In to your account</p>
                                            <CInputGroup className="mb-3">
                                                <CInputGroupText>
                                                    <CIcon icon={cilUser}/>
                                                </CInputGroupText>
                                                <CFormInput value={this.state.username} placeholder="Username"
                                                            onChange={e => {
                                                                this.setState({username: e.target.value})
                                                            }}
                                                            autoComplete="username"/>
                                            </CInputGroup>
                                            <CInputGroup className="mb-4">
                                                <CInputGroupText>
                                                    <CIcon icon={cilLockLocked}/>
                                                </CInputGroupText>
                                                <CFormInput
                                                    type="password"
                                                    placeholder="Password"
                                                    autoComplete="current-password"
                                                    onChange={e => {
                                                        this.setState({password: e.target.value})
                                                    }}
                                                    value={this.state.password}
                                                />
                                            </CInputGroup>
                                            <CRow>
                                                <CCol xs={6}>
                                                    <CButton color="primary" className="px-4" type={"submit"}
                                                             onClick={this.handleSubmit}>
                                                        Login
                                                    </CButton>
                                                </CCol>
                                            </CRow>
                                        </CForm>
                                    </CCardBody>
                                </CCard>

                            </CCardGroup>
                        </CCol>
                    </CRow>
                </CContainer>
            </div>
        )
    }
}

export default Login
