const token_key = "cmr777-token";

export const getSessionStorageItem = (key) => {
    const value = sessionStorage.getItem(token_key);
    return value ? JSON.parse(value)[key] : null;
};


export const getUserType = () => {
    const value = ''+parseInt(getSessionStorageItem('userType'), 10);
    return value ;
};
