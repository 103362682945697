import axios from 'axios';
import {getSessionStorageItem} from "./storageUtil";

const instance = axios.create({
    baseURL: `https://www.cmr777.com/api/`
});


instance.interceptors.request.use(function (config) {
    const token = getSessionStorageItem('jwt');
    config.headers.Authorization = token;
    return config;
});

export default instance;
